const themesList = [
  {
    value: "Legion;",
    label: "Default Theme",
    heading: "Nunito Sans",
    body: "Nunito Sans",
    url: '<link href="https://fonts.googleapis.com/css2?family=Nunito+Sans" rel="stylesheet">',
  },
  {
    value: "Pijar+Sekolah;",
    label: "Squad Pijar Sekolah",
    heading: "M Plus Rounded 1c",
    body: "M Plus Rounded 1c",
    url: '<link href="https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c" rel="stylesheet">',
  },
  {
    value: "Logee+Trans;",
    label: "Logee Transportation",
    heading: "Satoshi",
    body: "Satoshi",
    url: '<link href="https://api.fontshare.com/v2/css?f[]=satoshi" rel="stylesheet">',
  },
  {
    value: "Agree+Culture;",
    label: "Agree Culture",
    heading: "Montserrat",
    body: "Montserrat",
    bodySmall: "Open Sans",
    url: '<link href="https://fonts.googleapis.com/css2?family=Montserrat&family=Open+Sans" rel="stylesheet">',
  },
  {
    value: "GovMart;",
    label: "GovMart",
    heading: "Open Sans",
    body: "Open Sans",
    url: '<link href="https://fonts.googleapis.com/css2?family=Open+Sans" rel="stylesheet">',
  },
  {
    value: "BigBox;",
    label: "BigBox",
    heading: "Inter",
    body: "Inter",
    url: '<link href="https://fonts.googleapis.com/css2?family=Inter" rel="stylesheet">',
  },
  {
    value: "BPM;",
    label: "BPOM",
    heading: "Inter",
    body: "Inter",
    url: '<link href="https://fonts.googleapis.com/css2?family=Inter" rel="stylesheet">',
  },
  {
    value: "TKP;",
    label: "TKP",
    heading: "Nunito Sans",
    body: "Nunito Sans",
    url: '<link href="https://fonts.googleapis.com/css2?family=Nunito+Sans" rel="stylesheet">',
  },
  {
    value: "DigiTern;",
    label: "DigiTern",
    heading: "Lato",
    body: "Lato",
    url: '<link href="https://fonts.googleapis.com/css2?family=Lato" rel="stylesheet">',
  },
  {
    value: "OSS;",
    label: "OSS",
    heading: "Poppins",
    body: "Poppins",
    url: '<link href="https://fonts.googleapis.com/css2?family=Poppins" rel="stylesheet">',
  },
  {
    value: "Padi+UMKM;",
    label: "Padi UMKM",
    heading: "Ubuntu",
    body: "Ubuntu",
    url: '<link href="https://fonts.googleapis.com/css2?family=Ubuntu" rel="stylesheet">',
  },
  {
    value: "My+Tens;",
    label: "My Tens",
    heading: "Open Sans",
    body: "Nunito Sans",
    url: '<link href="https://fonts.googleapis.com/css2?family=Open+Sans&family=Nunito+Sans" rel="stylesheet">',
  },
];

const themes = [
  {
    label: "Others",
    options: [
      {
        value: "Legion;",
        label: "Legion Theme",
        heading: "Nunito Sans",
        body: "Nunito Sans",
        url: '<link href="https://fonts.googleapis.com/css2?family=Nunito+Sans" rel="stylesheet">',
      },
      {
        value: "Telkom+Indonesia;",
        label: "Telkom Indonesia",
        heading: "",
        body: "",
        url: "",
      },
    ],
  },
  {
    label: "EDU",
    options: [
      {
        value: "Pijar+Sekolah;",
        label: "Squad Pijar Sekolah",
        heading: "M Plus Rounded 1c",
        body: "M Plus Rounded 1c",
        url: '<link href="https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c" rel="stylesheet">',
      },
    ],
  },
  {
    label: "LOGEE",
    options: [
      {
        value: "Logee+Trans;",
        label: "Logee Transportation",
        heading: "Satoshi",
        body: "Satoshi",
        url: '<link href="https://api.fontshare.com/v2/css?f[]=satoshi" rel="stylesheet">',
      },
    ],
  },
  {
    label: "AGREE",
    options: [
      {
        value: "Agree+Culture;",
        label: "Agree Culture",
        heading: "Montserrat",
        body: "Montserrat",
        bodySmall: "Open Sans",
        url: '<link href="https://fonts.googleapis.com/css2?family=Montserrat&family=Open+Sans" rel="stylesheet">',
      },
    ],
  },
  {
    label: "GTP",
    options: [
      {
        value: "GovMart;",
        label: "GovMart",
        heading: "Open Sans",
        body: "Open Sans",
        url: '<link href="https://fonts.googleapis.com/css2?family=Open+Sans" rel="stylesheet">',
      },
    ],
  },
  {
    label: "BSP",
    options: [
      {
        value: "BigBox;",
        label: "BigBox",
        heading: "Inter",
        body: "Inter",
        url: '<link href="https://fonts.googleapis.com/css2?family=Inter" rel="stylesheet">',
      },
      {
        value: "BPM;",
        label: "BPOM",
        heading: "Inter",
        body: "Inter",
        url: '<link href="https://fonts.googleapis.com/css2?family=Inter" rel="stylesheet">',
      },
    ],
  },
  {
    label: "DBE",
    options: [
      {
        value: "DigiTern;",
        label: "DigiTern",
        heading: "Lato",
        body: "Lato",
        url: '<link href="https://fonts.googleapis.com/css2?family=Lato" rel="stylesheet">',
      },
      {
        value: "TKP;",
        label: "TKP",
        heading: "Nunito Sans",
        body: "Nunito Sans",
        url: '<link href="https://fonts.googleapis.com/css2?family=Nunito+Sans" rel="stylesheet">',
      },
    ],
  },
  ,
  {
    label: "TOS",
    options: [
      {
        value: "OSS;",
        label: "OSS",
        heading: "Poppins",
        body: "Poppins",
        url: '<link href="https://fonts.googleapis.com/css2?family=Poppins" rel="stylesheet">',
      },
    ],
  },
  {
    label: "SMB",
    options: [
      {
        value: "Padi+UMKM;",
        label: "Padi UMKM",
        heading: "Ubuntu",
        body: "Ubuntu",
        url: '<link href="https://fonts.googleapis.com/css2?family=Ubuntu" rel="stylesheet">',
      },
    ],
  },
  {
    label: "EWZ",
    options: [
      {
        value: "My+Tens;",
        label: "My Tens",
        heading: "Open Sans",
        body: "Nunito Sans",
        url: '<link href="https://fonts.googleapis.com/css2?family=Open+Sans&family=Nunito+Sans" rel="stylesheet">',
      },
      {
        value: "MyCarrier;",
        label: "My Carrier",
        heading: "Titilium Web",
        body: "Titilium Web",
        url: '<link href="https://fonts.googleapis.com/css2?family=Titillium+Web" rel="stylesheet">',
      },
    ],
  },
  {
    label: "DSZ",
    options: [
      {
        value: "FAB+Digital;",
        label: "FAB Digital",
        heading: "",
        body: "",
        url: "",
      },
    ],
  },
  {
    label: "TPN",
    options: [
      {
        value: "Odissey;",
        label: "Odissey",
        heading: "",
        body: "",
        url: "",
      },
    ],
  },
];

const checklists = {
  componentAccordion: {
    figmaUrl: {
      website:
        "https://www.figma.com/file/IAq40ai0JgOro9NSNrN95p/Website---UI-Component---Legion?type=design&node-id=11024-50773&mode=design",
      android:
        "https://www.figma.com/file/BLAvGtpuuckn60NVPCktPD/Android---UI-Component---Legion?type=design&node-id=14750-126&mode=design",
      ios: "https://www.figma.com/file/E55RZgxAnQaW51BDEWCaVS/Mobile---UI-Component---Legion?type=design&node-id=10988-82740&mode=design",
    },
    codeUrl: {
      website:
        "https://gitlab.playcourt.id/telkomdev/legion-phoenix/-/tree/master/packages/core/src/components/accordion",
      android: "#",
      ios: "https://gitlab.playcourt.id/mobileteam/legion-ios/-/tree/main/Sources/LegionUI/LGNComponent/LGNCustom/LGNAccordion",
    },
    checklists: {
      accessibilityStandard: [
        {
          checked: true,
          text: "We don't use color as the only visual tool to convey information.",
        },
      ],
      structureProperties: [
        {
          checked: true,
          text: "The component’s structure and properties include relevant options such as variant, style, size, orientation, optional iconography, decorations, selection, error state, etc.",
        },
        {
          checked: true,
          text: "The title is the component name that uses the frame base component template.",
        },
        {
          checked: true,
          text: 'The base component name contains: .Base & "Component Name" if there is more than one.',
        },
        {
          checked: true,
          text: "All component properties use the Legion foundation.",
        },
      ],
      designImplementationTest: [
        {
          checked: true,
          text: "We can change all the parts that are connected to the component base.",
        },
        {
          checked: true,
          text: "The inside of the base component remains connected to the master component.",
        },
        {
          checked: true,
          text: "All variant options are not damaged when we change from one to another.",
        },
        {
          checked: true,
          text: "Overriding changes to components will not reset other variants.",
        },
      ],
      guidelineComponent: [
        {
          checked: true,
          text: "Component's already has component specs documentation.",
        },
      ],
    },
  },
  componentAlert: {
    figmaUrl: {
      website:
        "https://www.figma.com/file/IAq40ai0JgOro9NSNrN95p/Website---UI-Component---Legion?type=design&node-id=12455-99905&mode=design",
      android:
        "https://www.figma.com/file/BLAvGtpuuckn60NVPCktPD/Android---UI-Component---Legion?type=design&node-id=14771-44354&mode=design",
      ios: "https://www.figma.com/file/Cnpho9ghJaLl2U7GWjr4kz/Master-Component-UI-Kit---Mobile?type=design&node-id=9570-31316&mode=design&t=sN6mSHpXFqWur4R3-0",
    },
    codeUrl: {
      website:
        "https://gitlab.playcourt.id/telkomdev/legion-phoenix/-/tree/master/packages/core/src/components/alert",
      android: "#",
      ios: "#",
    },
    checklists: {
      accessibilityStandard: [
        {
          checked: true,
          text: "We don't use color as the only visual tool to convey information.",
        },
      ],
      structureProperties: [
        {
          checked: true,
          text: "The component’s structure and properties include relevant options such as variant, style, size, orientation, optional iconography, decorations, selection, error state, etc.",
        },
        {
          checked: true,
          text: "The title is the component name that uses the frame base component template.",
        },
        {
          checked: true,
          text: 'The base component name contains: .Base & "Component Name" if there is more than one.',
        },
        {
          checked: true,
          text: "All component properties use the Legion foundation.",
        },
      ],
      designImplementationTest: [
        {
          checked: true,
          text: "We can change all the parts that are connected to the component base.",
        },
        {
          checked: true,
          text: "The inside of the base component remains connected to the master component.",
        },
        {
          checked: true,
          text: "All variant options are not damaged when we change from one to another.",
        },
        {
          checked: true,
          text: "Overriding changes to components will not reset other variants.",
        },
      ],
      guidelineComponent: [
        {
          checked: true,
          text: "Component's already has component specs documentation.",
        },
      ],
    },
  },
  componentAnchor: {
    figmaUrl: {
      website:
        "https://www.figma.com/file/IAq40ai0JgOro9NSNrN95p/Website---UI-Component---Legion?type=design&node-id=8519-91259&mode=design",
      android:
        "https://www.figma.com/file/BLAvGtpuuckn60NVPCktPD/Android---UI-Component---Legion?type=design&node-id=8543-111668&mode=design",
      ios: "https://www.figma.com/file/E55RZgxAnQaW51BDEWCaVS/Mobile---UI-Component---Legion?type=design&node-id=8543-111668&mode=design",
    },
    codeUrl: {
      website: "#",
      android:
        "https://gitlab.playcourt.id/mobileteam/legion-android/-/tree/develop/legion/component/src/main/java/com/telkom/legion/component/anchortext",
      ios: "https://gitlab.playcourt.id/mobileteam/legion-ios/-/tree/main/Sources/LegionUI/LGNComponent/LGNCustom/LGNAnchorText",
    },
    checklists: {
      accessibilityStandard: [
        {
          checked: true,
          text: "We don't use color as the only visual tool to convey information.",
        },
      ],
      structureProperties: [
        {
          checked: true,
          text: "The component’s structure and properties include relevant options such as variant, style, size, orientation, optional iconography, decorations, selection, error state, etc.",
        },
        {
          checked: true,
          text: "The title is the component name that uses the frame base component template.",
        },
        {
          checked: true,
          text: 'The base component name contains: .Base & "Component Name" if there is more than one.',
        },
        {
          checked: true,
          text: "All component properties use the Legion foundation.",
        },
      ],
      designImplementationTest: [
        {
          checked: true,
          text: "We can change all the parts that are connected to the component base.",
        },
        {
          checked: true,
          text: "The inside of the base component remains connected to the master component.",
        },
        {
          checked: true,
          text: "All variant options are not damaged when we change from one to another.",
        },
        {
          checked: true,
          text: "Overriding changes to components will not reset other variants.",
        },
      ],
      guidelineComponent: [
        {
          checked: true,
          text: "Component's already has component specs documentation.",
        },
      ],
    },
  },
  componentAvatar: {
    figmaUrl: {
      website:
        "https://www.figma.com/file/IAq40ai0JgOro9NSNrN95p/Website---UI-Component---Legion?type=design&node-id=6655-224358&mode=design",
      android: "#",
      ios: "https://www.figma.com/file/E55RZgxAnQaW51BDEWCaVS/Mobile---UI-Component---Legion?type=design&node-id=10901-144205&mode=design",
    },
    codeUrl: {
      website:
        "https://gitlab.playcourt.id/telkomdev/legion-phoenix/-/tree/master/packages/core/src/components/avatar",
      android:
        "https://gitlab.playcourt.id/mobileteam/legion-android/-/tree/develop/legion/component/src/main/java/com/telkom/legion/component/avatar",
      ios: "https://gitlab.playcourt.id/mobileteam/legion-ios/-/tree/main/Sources/LegionUI/LGNComponent/LGNCustom/LGNAvatar",
    },
    checklists: {
      accessibilityStandard: [
        {
          checked: true,
          text: "We don't use color as the only visual tool to convey information.",
        },
      ],
      structureProperties: [
        {
          checked: true,
          text: "The component’s structure and properties include relevant options such as variant, style, size, orientation, optional iconography, decorations, selection, error state, etc.",
        },
        {
          checked: true,
          text: "The title is the component name that uses the frame base component template.",
        },
        {
          checked: true,
          text: 'The base component name contains: .Base & "Component Name" if there is more than one.',
        },
        {
          checked: true,
          text: "All component properties use the Legion foundation.",
        },
      ],
      designImplementationTest: [
        {
          checked: true,
          text: "We can change all the parts that are connected to the component base.",
        },
        {
          checked: true,
          text: "The inside of the base component remains connected to the master component.",
        },
        {
          checked: true,
          text: "All variant options are not damaged when we change from one to another.",
        },
        {
          checked: true,
          text: "Overriding changes to components will not reset other variants.",
        },
      ],
      guidelineComponent: [
        {
          checked: true,
          text: "Component's already has component specs documentation.",
        },
      ],
    },
  },
  componentBadge: {
    figmaUrl: {
      website:
        "https://www.figma.com/file/IAq40ai0JgOro9NSNrN95p/Website---UI-Component---Legion?type=design&node-id=6655-224620&mode=design",
      android: "#",
      ios: "https://www.figma.com/file/Cnpho9ghJaLl2U7GWjr4kz/Master-Component-UI-Kit---Mobile?type=design&node-id=31-7676&mode=design&t=tHQRInaqN2MhHISf-0",
    },
    codeUrl: {
      website:
        "https://gitlab.playcourt.id/telkomdev/legion-phoenix/-/tree/master/packages/core/src/components/badge",
      android: "#",
      ios: "https://gitlab.playcourt.id/aridwan05/legion-ios/-/tree/Development/Sources/LegionUI/LGNComponent/LGNCustom/LGNBadge",
    },
    checklists: {
      accessibilityStandard: [
        {
          checked: true,
          text: "We don't use color as the only visual tool to convey information.",
        },
      ],
      structureProperties: [
        {
          checked: true,
          text: "The component’s structure and properties include relevant options such as variant, style, size, orientation, optional iconography, decorations, selection, error state, etc.",
        },
        {
          checked: true,
          text: "The title is the component name that uses the frame base component template.",
        },
        {
          checked: true,
          text: 'The base component name contains: .Base & "Component Name" if there is more than one.',
        },
        {
          checked: true,
          text: "All component properties use the Legion foundation.",
        },
      ],
      designImplementationTest: [
        {
          checked: true,
          text: "We can change all the parts that are connected to the component base.",
        },
        {
          checked: true,
          text: "The inside of the base component remains connected to the master component.",
        },
        {
          checked: true,
          text: "All variant options are not damaged when we change from one to another.",
        },
        {
          checked: true,
          text: "Overriding changes to components will not reset other variants.",
        },
      ],
      guidelineComponent: [
        {
          checked: true,
          text: "Component's already has component specs documentation.",
        },
      ],
    },
  },
  componentBottomSheet: {
    figmaUrl: {
      website: "#",
      android: "#",
      ios: "https://www.figma.com/file/E55RZgxAnQaW51BDEWCaVS/Mobile---UI-Component---Legion?type=design&node-id=10988-83145&mode=design",
    },
    codeUrl: {
      website: "#",
      android: "#",
      ios: "https://gitlab.playcourt.id/mobileteam/legion-ios/-/tree/main/Sources/LegionUI/LGNComponent/LGNCustom/LGNBottomSheet",
    },
    checklists: {
      accessibilityStandard: [
        {
          checked: true,
          text: "We don't use color as the only visual tool to convey information.",
        },
      ],
      structureProperties: [
        {
          checked: true,
          text: "The component’s structure and properties include relevant options such as variant, style, size, orientation, optional iconography, decorations, selection, error state, etc.",
        },
        {
          checked: true,
          text: "The title is the component name that uses the frame base component template.",
        },
        {
          checked: true,
          text: 'The base component name contains: .Base & "Component Name" if there is more than one.',
        },
        {
          checked: true,
          text: "All component properties use the Legion foundation.",
        },
      ],
      designImplementationTest: [
        {
          checked: true,
          text: "We can change all the parts that are connected to the component base.",
        },
        {
          checked: true,
          text: "The inside of the base component remains connected to the master component.",
        },
        {
          checked: true,
          text: "All variant options are not damaged when we change from one to another.",
        },
        {
          checked: true,
          text: "Overriding changes to components will not reset other variants.",
        },
      ],
      guidelineComponent: [
        {
          checked: true,
          text: "Component's already has component specs documentation.",
        },
      ],
    },
  },
  componentBox: {
    figmaUrl: {
      website: "#",
      android: "#",
      ios: "#",
    },
    codeUrl: { website: "#", android: "#", ios: "#" },
    checklists: {
      accessibilityStandard: [
        {
          checked: true,
          text: "We don't use color as the only visual tool to convey information.",
        },
      ],
      structureProperties: [
        {
          checked: true,
          text: "The component’s structure and properties include relevant options such as variant, style, size, orientation, optional iconography, decorations, selection, error state, etc.",
        },
        {
          checked: true,
          text: "The title is the component name that uses the frame base component template.",
        },
        {
          checked: true,
          text: 'The base component name contains: .Base & "Component Name" if there is more than one.',
        },
        {
          checked: true,
          text: "All component properties use the Legion foundation.",
        },
      ],
      designImplementationTest: [
        {
          checked: true,
          text: "We can change all the parts that are connected to the component base.",
        },
        {
          checked: true,
          text: "The inside of the base component remains connected to the master component.",
        },
        {
          checked: true,
          text: "All variant options are not damaged when we change from one to another.",
        },
        {
          checked: true,
          text: "Overriding changes to components will not reset other variants.",
        },
      ],
      guidelineComponent: [
        {
          checked: true,
          text: "Component's already has component specs documentation.",
        },
      ],
    },
  },
  componentButton: {
    figmaUrl: {
      website:
        "https://www.figma.com/file/IAq40ai0JgOro9NSNrN95p/Website---UI-Component---Legion?type=design&node-id=6655-206052&mode=design",
      android:
        "https://www.figma.com/file/BLAvGtpuuckn60NVPCktPD/Android---UI-Component---Legion?type=design&node-id=6655-206052&mode=design",
      ios: "https://www.figma.com/file/E55RZgxAnQaW51BDEWCaVS/Mobile---UI-Component---Legion?type=design&node-id=10899-156553&mode=design&t=7xjGazBqKzw5OWeS-4",
    },
    codeUrl: {
      website:
        "https://gitlab.playcourt.id/telkomdev/legion-phoenix/-/tree/master/packages/core/src/components/button",
      android:
        "https://gitlab.playcourt.id/mobileteam/legion-android/-/tree/develop/legion/component/src/main/java/com/telkom/legion/component/button",
      ios: "https://gitlab.playcourt.id/mobileteam/legion-ios/-/tree/main/Sources/LegionUI/LGNComponent/LGNCustom/LGNBtn",
    },
    checklists: {
      accessibilityStandard: [
        {
          checked: true,
          text: "We don't use color as the only visual tool to convey information.",
        },
      ],
      structureProperties: [
        {
          checked: true,
          text: "The component’s structure and properties include relevant options such as variant, style, size, orientation, optional iconography, decorations, selection, error state, etc.",
        },
        {
          checked: true,
          text: "The title is the component name that uses the frame base component template.",
        },
        {
          checked: true,
          text: 'The base component name contains: .Base & "Component Name" if there is more than one.',
        },
        {
          checked: true,
          text: "All component properties use the Legion foundation.",
        },
      ],
      designImplementationTest: [
        {
          checked: true,
          text: "We can change all the parts that are connected to the component base.",
        },
        {
          checked: true,
          text: "The inside of the base component remains connected to the master component.",
        },
        {
          checked: true,
          text: "All variant options are not damaged when we change from one to another.",
        },
        {
          checked: true,
          text: "Overriding changes to components will not reset other variants.",
        },
      ],
      guidelineComponent: [
        {
          checked: true,
          text: "Component's already has component specs documentation.",
        },
      ],
    },
  },
  componentCard: {
    figmaUrl: {
      website:
        "https://www.figma.com/file/IAq40ai0JgOro9NSNrN95p/Website---UI-Component---Legion?type=design&node-id=11662-93465&mode=design",
      android: "#",
      ios: "#",
    },
    codeUrl: {
      website:
        "https://gitlab.playcourt.id/telkomdev/legion-phoenix/-/tree/master/packages/core/src/components/card",
      android: "#",
      ios: "#",
    },
    checklists: {
      accessibilityStandard: [
        {
          checked: true,
          text: "We don't use color as the only visual tool to convey information.",
        },
      ],
      structureProperties: [
        {
          checked: true,
          text: "The component’s structure and properties include relevant options such as variant, style, size, orientation, optional iconography, decorations, selection, error state, etc.",
        },
        {
          checked: true,
          text: "The title is the component name that uses the frame base component template.",
        },
        {
          checked: true,
          text: 'The base component name contains: .Base & "Component Name" if there is more than one.',
        },
        {
          checked: true,
          text: "All component properties use the Legion foundation.",
        },
      ],
      designImplementationTest: [
        {
          checked: true,
          text: "We can change all the parts that are connected to the component base.",
        },
        {
          checked: true,
          text: "The inside of the base component remains connected to the master component.",
        },
        {
          checked: true,
          text: "All variant options are not damaged when we change from one to another.",
        },
        {
          checked: true,
          text: "Overriding changes to components will not reset other variants.",
        },
      ],
      guidelineComponent: [
        {
          checked: true,
          text: "Component's already has component specs documentation.",
        },
      ],
    },
  },
  componentCarousel: {
    figmaUrl: {
      website:
        "https://www.figma.com/file/IAq40ai0JgOro9NSNrN95p/Website---UI-Component---Legion?type=design&node-id=12128-94082&mode=design",
      android: "#",
      ios: "#",
    },
    codeUrl: { website: "#", android: "#", ios: "#" },
    checklists: {
      accessibilityStandard: [
        {
          checked: true,
          text: "We don't use color as the only visual tool to convey information.",
        },
      ],
      structureProperties: [
        {
          checked: true,
          text: "The component’s structure and properties include relevant options such as variant, style, size, orientation, optional iconography, decorations, selection, error state, etc.",
        },
        {
          checked: true,
          text: "The title is the component name that uses the frame base component template.",
        },
        {
          checked: true,
          text: 'The base component name contains: .Base & "Component Name" if there is more than one.',
        },
        {
          checked: true,
          text: "All component properties use the Legion foundation.",
        },
      ],
      designImplementationTest: [
        {
          checked: true,
          text: "We can change all the parts that are connected to the component base.",
        },
        {
          checked: true,
          text: "The inside of the base component remains connected to the master component.",
        },
        {
          checked: true,
          text: "All variant options are not damaged when we change from one to another.",
        },
        {
          checked: true,
          text: "Overriding changes to components will not reset other variants.",
        },
      ],
      guidelineComponent: [
        {
          checked: true,
          text: "Component's already has component specs documentation.",
        },
      ],
    },
  },
  componentCheckbox: {
    figmaUrl: {
      website:
        "https://www.figma.com/file/IAq40ai0JgOro9NSNrN95p/Website---UI-Component---Legion?type=design&node-id=6655-216046&mode=design",
      android:
        "https://www.figma.com/file/BLAvGtpuuckn60NVPCktPD/Android---UI-Component---Legion?type=design&node-id=6655-216046&mode=design",
      ios: "https://www.figma.com/file/E55RZgxAnQaW51BDEWCaVS/Mobile---UI-Component---Legion?type=design&node-id=6655-216046&mode=design",
    },
    codeUrl: {
      website:
        "https://gitlab.playcourt.id/telkomdev/legion-phoenix/-/tree/master/packages/core/src/components/checkbox",
      android:
        "https://gitlab.playcourt.id/mobileteam/legion-android/-/tree/develop/legion/component/src/main/java/com/telkom/legion/component/checkbox",
      ios: "https://gitlab.playcourt.id/mobileteam/legion-ios/-/tree/main/Sources/LegionUI/LGNComponent/LGNCustom/LGNCheckbox",
    },
    checklists: {
      accessibilityStandard: [
        {
          checked: true,
          text: "We don't use color as the only visual tool to convey information.",
        },
      ],
      structureProperties: [
        {
          checked: true,
          text: "The component’s structure and properties include relevant options such as variant, style, size, orientation, optional iconography, decorations, selection, error state, etc.",
        },
        {
          checked: true,
          text: "The title is the component name that uses the frame base component template.",
        },
        {
          checked: true,
          text: 'The base component name contains: .Base & "Component Name" if there is more than one.',
        },
        {
          checked: true,
          text: "All component properties use the Legion foundation.",
        },
      ],
      designImplementationTest: [
        {
          checked: true,
          text: "We can change all the parts that are connected to the component base.",
        },
        {
          checked: true,
          text: "The inside of the base component remains connected to the master component.",
        },
        {
          checked: true,
          text: "All variant options are not damaged when we change from one to another.",
        },
        {
          checked: true,
          text: "Overriding changes to components will not reset other variants.",
        },
      ],
      guidelineComponent: [
        {
          checked: true,
          text: "Component's already has component specs documentation.",
        },
      ],
    },
  },
  componentChips: {
    figmaUrl: {
      website:
        "https://www.figma.com/file/IAq40ai0JgOro9NSNrN95p/Website---UI-Component---Legion?type=design&node-id=12583-138297&mode=design",
      android:
        "https://www.figma.com/file/BLAvGtpuuckn60NVPCktPD/Android---UI-Component---Legion?type=design&node-id=14764-30345&mode=design",
      ios: "https://www.figma.com/file/E55RZgxAnQaW51BDEWCaVS/Mobile---UI-Component---Legion?type=design&node-id=10988-73081&mode=design",
    },
    codeUrl: {
      website:
        "https://gitlab.playcourt.id/telkomdev/legion-phoenix/-/tree/master/packages/core/src/components/chip",
      android: "#",
      ios: "https://gitlab.playcourt.id/mobileteam/legion-ios/-/tree/main/Sources/LegionUI/LGNComponent/LGNCustom/LGNChips",
    },
    checklists: {
      accessibilityStandard: [
        {
          checked: true,
          text: "We don't use color as the only visual tool to convey information.",
        },
      ],
      structureProperties: [
        {
          checked: true,
          text: "The component’s structure and properties include relevant options such as variant, style, size, orientation, optional iconography, decorations, selection, error state, etc.",
        },
        {
          checked: true,
          text: "The title is the component name that uses the frame base component template.",
        },
        {
          checked: true,
          text: 'The base component name contains: .Base & "Component Name" if there is more than one.',
        },
        {
          checked: true,
          text: "All component properties use the Legion foundation.",
        },
      ],
      designImplementationTest: [
        {
          checked: true,
          text: "We can change all the parts that are connected to the component base.",
        },
        {
          checked: true,
          text: "The inside of the base component remains connected to the master component.",
        },
        {
          checked: true,
          text: "All variant options are not damaged when we change from one to another.",
        },
        {
          checked: true,
          text: "Overriding changes to components will not reset other variants.",
        },
      ],
      guidelineComponent: [
        {
          checked: true,
          text: "Component's already has component specs documentation.",
        },
      ],
    },
  },
  componentDatePicker: {
    figmaUrl: {
      website:
        "https://www.figma.com/file/IAq40ai0JgOro9NSNrN95p/Website---UI-Component---Legion?type=design&node-id=11539-71515&mode=design",
      android: "#",
      ios: "#",
    },
    codeUrl: {
      website:
        "https://gitlab.playcourt.id/telkomdev/legion-phoenix/-/tree/master/packages/dates/src",
      android: "#",
      ios: "#",
    },
    checklists: {
      accessibilityStandard: [
        {
          checked: true,
          text: "We don't use color as the only visual tool to convey information.",
        },
      ],
      structureProperties: [
        {
          checked: true,
          text: "The component’s structure and properties include relevant options such as variant, style, size, orientation, optional iconography, decorations, selection, error state, etc.",
        },
        {
          checked: true,
          text: "The title is the component name that uses the frame base component template.",
        },
        {
          checked: true,
          text: 'The base component name contains: .Base & "Component Name" if there is more than one.',
        },
        {
          checked: true,
          text: "All component properties use the Legion foundation.",
        },
      ],
      designImplementationTest: [
        {
          checked: true,
          text: "We can change all the parts that are connected to the component base.",
        },
        {
          checked: true,
          text: "The inside of the base component remains connected to the master component.",
        },
        {
          checked: true,
          text: "All variant options are not damaged when we change from one to another.",
        },
        {
          checked: true,
          text: "Overriding changes to components will not reset other variants.",
        },
      ],
      guidelineComponent: [
        {
          checked: true,
          text: "Component's already has component specs documentation.",
        },
      ],
    },
  },
  componentDivider: {
    figmaUrl: {
      website:
        "https://www.figma.com/file/IAq40ai0JgOro9NSNrN95p/Website---UI-Component---Legion?type=design&node-id=11024-66706&mode=design",
      android:
        "https://www.figma.com/file/BLAvGtpuuckn60NVPCktPD/Android---UI-Component---Legion?type=design&node-id=14753-10046&mode=design",
      ios: "https://www.figma.com/file/Cnpho9ghJaLl2U7GWjr4kz/Master-Component-UI-Kit---Mobile?type=design&node-id=9570-31316&mode=design&t=sN6mSHpXFqWur4R3-0",
    },
    codeUrl: {
      website:
        "https://gitlab.playcourt.id/telkomdev/legion-phoenix/-/tree/master/packages/core/src/components/divider",
      android: "#",
      ios: "#",
    },
    checklists: {
      accessibilityStandard: [
        {
          checked: true,
          text: "We don't use color as the only visual tool to convey information.",
        },
      ],
      structureProperties: [
        {
          checked: true,
          text: "The component’s structure and properties include relevant options such as variant, style, size, orientation, optional iconography, decorations, selection, error state, etc.",
        },
        {
          checked: true,
          text: "The title is the component name that uses the frame base component template.",
        },
        {
          checked: true,
          text: 'The base component name contains: .Base & "Component Name" if there is more than one.',
        },
        {
          checked: true,
          text: "All component properties use the Legion foundation.",
        },
      ],
      designImplementationTest: [
        {
          checked: true,
          text: "We can change all the parts that are connected to the component base.",
        },
        {
          checked: true,
          text: "The inside of the base component remains connected to the master component.",
        },
        {
          checked: true,
          text: "All variant options are not damaged when we change from one to another.",
        },
        {
          checked: true,
          text: "Overriding changes to components will not reset other variants.",
        },
      ],
      guidelineComponent: [
        {
          checked: true,
          text: "Component's already has component specs documentation.",
        },
      ],
    },
  },
  componentDropdown: {
    figmaUrl: {
      website:
        "https://www.figma.com/file/IAq40ai0JgOro9NSNrN95p/Website---UI-Component---Legion?type=design&node-id=12130-94195&mode=design",
      android: "#",
      ios: "#",
    },
    codeUrl: {
      website:
        "https://gitlab.playcourt.id/telkomdev/legion-phoenix/-/tree/master/packages/core/src/components/dropdown",
      android: "#",
      ios: "#",
    },
    checklists: {
      accessibilityStandard: [
        {
          checked: true,
          text: "We don't use color as the only visual tool to convey information.",
        },
      ],
      structureProperties: [
        {
          checked: true,
          text: "The component’s structure and properties include relevant options such as variant, style, size, orientation, optional iconography, decorations, selection, error state, etc.",
        },
        {
          checked: true,
          text: "The title is the component name that uses the frame base component template.",
        },
        {
          checked: true,
          text: 'The base component name contains: .Base & "Component Name" if there is more than one.',
        },
        {
          checked: true,
          text: "All component properties use the Legion foundation.",
        },
      ],
      designImplementationTest: [
        {
          checked: true,
          text: "We can change all the parts that are connected to the component base.",
        },
        {
          checked: true,
          text: "The inside of the base component remains connected to the master component.",
        },
        {
          checked: true,
          text: "All variant options are not damaged when we change from one to another.",
        },
        {
          checked: true,
          text: "Overriding changes to components will not reset other variants.",
        },
      ],
      guidelineComponent: [
        {
          checked: true,
          text: "Component's already has component specs documentation.",
        },
      ],
    },
  },
  componentFlex: {
    figmaUrl: {
      website: "#",
      android: "#",
      ios: "#",
    },
    codeUrl: { website: "#", android: "#", ios: "#" },
    checklists: {
      accessibilityStandard: [
        {
          checked: true,
          text: "We don't use color as the only visual tool to convey information.",
        },
      ],
      structureProperties: [
        {
          checked: true,
          text: "The component’s structure and properties include relevant options such as variant, style, size, orientation, optional iconography, decorations, selection, error state, etc.",
        },
        {
          checked: true,
          text: "The title is the component name that uses the frame base component template.",
        },
        {
          checked: true,
          text: 'The base component name contains: .Base & "Component Name" if there is more than one.',
        },
        {
          checked: true,
          text: "All component properties use the Legion foundation.",
        },
      ],
      designImplementationTest: [
        {
          checked: true,
          text: "We can change all the parts that are connected to the component base.",
        },
        {
          checked: true,
          text: "The inside of the base component remains connected to the master component.",
        },
        {
          checked: true,
          text: "All variant options are not damaged when we change from one to another.",
        },
        {
          checked: true,
          text: "Overriding changes to components will not reset other variants.",
        },
      ],
      guidelineComponent: [
        {
          checked: true,
          text: "Component's already has component specs documentation.",
        },
      ],
    },
  },
  componentGrid: {
    figmaUrl: {
      website: "#",
      android: "#",
      ios: "#",
    },
    codeUrl: { website: "#", android: "#", ios: "#" },
    checklists: {
      accessibilityStandard: [
        {
          checked: true,
          text: "We don't use color as the only visual tool to convey information.",
        },
      ],
      structureProperties: [
        {
          checked: true,
          text: "The component’s structure and properties include relevant options such as variant, style, size, orientation, optional iconography, decorations, selection, error state, etc.",
        },
        {
          checked: true,
          text: "The title is the component name that uses the frame base component template.",
        },
        {
          checked: true,
          text: 'The base component name contains: .Base & "Component Name" if there is more than one.',
        },
        {
          checked: true,
          text: "All component properties use the Legion foundation.",
        },
      ],
      designImplementationTest: [
        {
          checked: true,
          text: "We can change all the parts that are connected to the component base.",
        },
        {
          checked: true,
          text: "The inside of the base component remains connected to the master component.",
        },
        {
          checked: true,
          text: "All variant options are not damaged when we change from one to another.",
        },
        {
          checked: true,
          text: "Overriding changes to components will not reset other variants.",
        },
      ],
      guidelineComponent: [
        {
          checked: true,
          text: "Component's already has component specs documentation.",
        },
      ],
    },
  },
  componentImage: {
    figmaUrl: {
      website:
        "https://www.figma.com/file/IAq40ai0JgOro9NSNrN95p/Website---UI-Component---Legion?type=design&node-id=11024-50404&mode=design",
      android:
        "https://www.figma.com/file/BLAvGtpuuckn60NVPCktPD/Android---UI-Component---Legion?type=design&node-id=14753-9218&mode=design",
      ios: "https://www.figma.com/file/E55RZgxAnQaW51BDEWCaVS/Mobile---UI-Component---Legion?type=design&node-id=10988-73308&mode=design",
    },
    codeUrl: {
      website:
        "https://gitlab.playcourt.id/telkomdev/legion-phoenix/-/tree/master/packages/core/src/components/image",
      android: "#",
      ios: "#",
    },
    checklists: {
      accessibilityStandard: [
        {
          checked: true,
          text: "We don't use color as the only visual tool to convey information.",
        },
      ],
      structureProperties: [
        {
          checked: true,
          text: "The component’s structure and properties include relevant options such as variant, style, size, orientation, optional iconography, decorations, selection, error state, etc.",
        },
        {
          checked: true,
          text: "The title is the component name that uses the frame base component template.",
        },
        {
          checked: true,
          text: 'The base component name contains: .Base & "Component Name" if there is more than one.',
        },
        {
          checked: true,
          text: "All component properties use the Legion foundation.",
        },
      ],
      designImplementationTest: [
        {
          checked: true,
          text: "We can change all the parts that are connected to the component base.",
        },
        {
          checked: true,
          text: "The inside of the base component remains connected to the master component.",
        },
        {
          checked: true,
          text: "All variant options are not damaged when we change from one to another.",
        },
        {
          checked: true,
          text: "Overriding changes to components will not reset other variants.",
        },
      ],
      guidelineComponent: [
        {
          checked: true,
          text: "Component's already has component specs documentation.",
        },
      ],
    },
  },
  componentMegaMenu: {
    figmaUrl: {
      website:
        "https://www.figma.com/file/IAq40ai0JgOro9NSNrN95p/Website---UI-Component---Legion?type=design&node-id=12457-97365&mode=design",
      android: "#",
      ios: "#",
    },
    codeUrl: { website: "#", android: "#", ios: "#" },
    checklists: {
      accessibilityStandard: [
        {
          checked: true,
          text: "We don't use color as the only visual tool to convey information.",
        },
      ],
      structureProperties: [
        {
          checked: true,
          text: "The component’s structure and properties include relevant options such as variant, style, size, orientation, optional iconography, decorations, selection, error state, etc.",
        },
        {
          checked: true,
          text: "The title is the component name that uses the frame base component template.",
        },
        {
          checked: true,
          text: 'The base component name contains: .Base & "Component Name" if there is more than one.',
        },
        {
          checked: true,
          text: "All component properties use the Legion foundation.",
        },
      ],
      designImplementationTest: [
        {
          checked: true,
          text: "We can change all the parts that are connected to the component base.",
        },
        {
          checked: true,
          text: "The inside of the base component remains connected to the master component.",
        },
        {
          checked: true,
          text: "All variant options are not damaged when we change from one to another.",
        },
        {
          checked: true,
          text: "Overriding changes to components will not reset other variants.",
        },
      ],
      guidelineComponent: [
        {
          checked: true,
          text: "Component's already has component specs documentation.",
        },
      ],
    },
  },
  componentModal: {
    figmaUrl: {
      website:
        "https://www.figma.com/file/IAq40ai0JgOro9NSNrN95p/Website---UI-Component---Legion?type=design&node-id=11024-51318&mode=design",
      android: "#",
      ios: "https://www.figma.com/file/E55RZgxAnQaW51BDEWCaVS/Mobile---UI-Component---Legion?type=design&node-id=10065-203578&mode=design",
    },
    codeUrl: {
      website:
        "https://gitlab.playcourt.id/telkomdev/legion-phoenix/-/tree/master/packages/core/src/components/modal",
      android: "#",
      ios: "#",
    },
    checklists: {
      accessibilityStandard: [
        {
          checked: true,
          text: "We don't use color as the only visual tool to convey information.",
        },
      ],
      structureProperties: [
        {
          checked: true,
          text: "The component’s structure and properties include relevant options such as variant, style, size, orientation, optional iconography, decorations, selection, error state, etc.",
        },
        {
          checked: true,
          text: "The title is the component name that uses the frame base component template.",
        },
        {
          checked: true,
          text: 'The base component name contains: .Base & "Component Name" if there is more than one.',
        },
        {
          checked: true,
          text: "All component properties use the Legion foundation.",
        },
      ],
      designImplementationTest: [
        {
          checked: true,
          text: "We can change all the parts that are connected to the component base.",
        },
        {
          checked: true,
          text: "The inside of the base component remains connected to the master component.",
        },
        {
          checked: true,
          text: "All variant options are not damaged when we change from one to another.",
        },
        {
          checked: true,
          text: "Overriding changes to components will not reset other variants.",
        },
      ],
      guidelineComponent: [
        {
          checked: true,
          text: "Component's already has component specs documentation.",
        },
      ],
    },
  },
  componentNavigationBar: {
    figmaUrl: {
      website:
        "https://www.figma.com/file/IAq40ai0JgOro9NSNrN95p/Website---UI-Component---Legion?type=design&node-id=11667-81553&mode=design",
      android: "#",
      ios: "https://www.figma.com/file/E55RZgxAnQaW51BDEWCaVS/Mobile---UI-Component---Legion?type=design&node-id=8227-63051&mode=design",
    },
    codeUrl: {
      website:
        "https://gitlab.playcourt.id/telkomdev/legion-phoenix/-/tree/master/packages/core/src/components/navbar",
      android: "#",
      ios: "#",
    },
    checklists: {
      accessibilityStandard: [
        {
          checked: true,
          text: "We don't use color as the only visual tool to convey information.",
        },
      ],
      structureProperties: [
        {
          checked: true,
          text: "The component’s structure and properties include relevant options such as variant, style, size, orientation, optional iconography, decorations, selection, error state, etc.",
        },
        {
          checked: true,
          text: "The title is the component name that uses the frame base component template.",
        },
        {
          checked: true,
          text: 'The base component name contains: .Base & "Component Name" if there is more than one.',
        },
        {
          checked: true,
          text: "All component properties use the Legion foundation.",
        },
      ],
      designImplementationTest: [
        {
          checked: true,
          text: "We can change all the parts that are connected to the component base.",
        },
        {
          checked: true,
          text: "The inside of the base component remains connected to the master component.",
        },
        {
          checked: true,
          text: "All variant options are not damaged when we change from one to another.",
        },
        {
          checked: true,
          text: "Overriding changes to components will not reset other variants.",
        },
      ],
      guidelineComponent: [
        {
          checked: true,
          text: "Component's already has component specs documentation.",
        },
      ],
    },
  },
  componentPinInput: {
    figmaUrl: {
      website: "#",
      android: "#",
      ios: "#",
    },
    codeUrl: { website: "#", android: "#", ios: "#" },
    checklists: {
      accessibilityStandard: [
        {
          checked: true,
          text: "We don't use color as the only visual tool to convey information.",
        },
      ],
      structureProperties: [
        {
          checked: true,
          text: "The component’s structure and properties include relevant options such as variant, style, size, orientation, optional iconography, decorations, selection, error state, etc.",
        },
        {
          checked: true,
          text: "The title is the component name that uses the frame base component template.",
        },
        {
          checked: true,
          text: 'The base component name contains: .Base & "Component Name" if there is more than one.',
        },
        {
          checked: true,
          text: "All component properties use the Legion foundation.",
        },
      ],
      designImplementationTest: [
        {
          checked: true,
          text: "We can change all the parts that are connected to the component base.",
        },
        {
          checked: true,
          text: "The inside of the base component remains connected to the master component.",
        },
        {
          checked: true,
          text: "All variant options are not damaged when we change from one to another.",
        },
        {
          checked: true,
          text: "Overriding changes to components will not reset other variants.",
        },
      ],
      guidelineComponent: [
        {
          checked: true,
          text: "Component's already has component specs documentation.",
        },
      ],
    },
  },
  componentRadioButton: {
    figmaUrl: {
      website:
        "https://www.figma.com/file/IAq40ai0JgOro9NSNrN95p/Website---UI-Component---Legion?type=design&node-id=6655-216754&mode=design",
      android:
        "https://www.figma.com/file/BLAvGtpuuckn60NVPCktPD/Android---UI-Component---Legion?type=design&node-id=6655-216754&mode=design",
      ios: "https://www.figma.com/file/E55RZgxAnQaW51BDEWCaVS/Mobile---UI-Component---Legion?type=design&node-id=6655-216754&mode=design",
    },
    codeUrl: {
      website:
        "https://gitlab.playcourt.id/telkomdev/legion-phoenix/-/tree/master/packages/core/src/components/radio",
      android:
        "https://gitlab.playcourt.id/mobileteam/legion-android/-/tree/develop/legion/component/src/main/java/com/telkom/legion/component/radio",
      ios: "https://gitlab.playcourt.id/mobileteam/legion-ios/-/tree/main/Sources/LegionUI/LGNComponent/LGNCustom/LGNRadioBtn",
    },
    checklists: {
      accessibilityStandard: [
        {
          checked: true,
          text: "We don't use color as the only visual tool to convey information.",
        },
      ],
      structureProperties: [
        {
          checked: true,
          text: "The component’s structure and properties include relevant options such as variant, style, size, orientation, optional iconography, decorations, selection, error state, etc.",
        },
        {
          checked: true,
          text: "The title is the component name that uses the frame base component template.",
        },
        {
          checked: true,
          text: 'The base component name contains: .Base & "Component Name" if there is more than one.',
        },
        {
          checked: true,
          text: "All component properties use the Legion foundation.",
        },
      ],
      designImplementationTest: [
        {
          checked: true,
          text: "We can change all the parts that are connected to the component base.",
        },
        {
          checked: true,
          text: "The inside of the base component remains connected to the master component.",
        },
        {
          checked: true,
          text: "All variant options are not damaged when we change from one to another.",
        },
        {
          checked: true,
          text: "Overriding changes to components will not reset other variants.",
        },
      ],
      guidelineComponent: [
        {
          checked: true,
          text: "Component's already has component specs documentation.",
        },
      ],
    },
  },
  componentSelectField: {
    figmaUrl: {
      website:
        "https://www.figma.com/file/IAq40ai0JgOro9NSNrN95p/Website---UI-Component---Legion?type=design&node-id=12057-90639&mode=design",
      android: "#",
      ios: "#",
    },
    codeUrl: {
      website:
        "https://gitlab.playcourt.id/telkomdev/legion-phoenix/-/tree/master/packages/core/src/components/select",
      android: "#",
      ios: "#",
    },
    checklists: {
      accessibilityStandard: [
        {
          checked: true,
          text: "We don't use color as the only visual tool to convey information.",
        },
      ],
      structureProperties: [
        {
          checked: true,
          text: "The component’s structure and properties include relevant options such as variant, style, size, orientation, optional iconography, decorations, selection, error state, etc.",
        },
        {
          checked: true,
          text: "The title is the component name that uses the frame base component template.",
        },
        {
          checked: true,
          text: 'The base component name contains: .Base & "Component Name" if there is more than one.',
        },
        {
          checked: true,
          text: "All component properties use the Legion foundation.",
        },
      ],
      designImplementationTest: [
        {
          checked: true,
          text: "We can change all the parts that are connected to the component base.",
        },
        {
          checked: true,
          text: "The inside of the base component remains connected to the master component.",
        },
        {
          checked: true,
          text: "All variant options are not damaged when we change from one to another.",
        },
        {
          checked: true,
          text: "Overriding changes to components will not reset other variants.",
        },
      ],
      guidelineComponent: [
        {
          checked: true,
          text: "Component's already has component specs documentation.",
        },
      ],
    },
  },
  componentSideBar: {
    figmaUrl: {
      website:
        "https://www.figma.com/file/IAq40ai0JgOro9NSNrN95p/Website---UI-Component---Legion?type=design&node-id=11387-66017&mode=design",
      android: "#",
      ios: "#",
    },
    codeUrl: {
      website:
        "https://gitlab.playcourt.id/telkomdev/legion-phoenix/-/tree/master/packages/core/src/components/sidebar",
      android: "#",
      ios: "#",
    },
    checklists: {
      accessibilityStandard: [
        {
          checked: true,
          text: "We don't use color as the only visual tool to convey information.",
        },
      ],
      structureProperties: [
        {
          checked: true,
          text: "The component’s structure and properties include relevant options such as variant, style, size, orientation, optional iconography, decorations, selection, error state, etc.",
        },
        {
          checked: true,
          text: "The title is the component name that uses the frame base component template.",
        },
        {
          checked: true,
          text: 'The base component name contains: .Base & "Component Name" if there is more than one.',
        },
        {
          checked: true,
          text: "All component properties use the Legion foundation.",
        },
      ],
      designImplementationTest: [
        {
          checked: true,
          text: "We can change all the parts that are connected to the component base.",
        },
        {
          checked: true,
          text: "The inside of the base component remains connected to the master component.",
        },
        {
          checked: true,
          text: "All variant options are not damaged when we change from one to another.",
        },
        {
          checked: true,
          text: "Overriding changes to components will not reset other variants.",
        },
      ],
      guidelineComponent: [
        {
          checked: true,
          text: "Component's already has component specs documentation.",
        },
      ],
    },
  },
  componentSnackBar: {
    figmaUrl: {
      website:
        "https://www.figma.com/file/IAq40ai0JgOro9NSNrN95p/Website---UI-Component---Legion?type=design&node-id=9338-121615&mode=design",
      android: "#",
      ios: "https://www.figma.com/file/E55RZgxAnQaW51BDEWCaVS/Mobile---UI-Component---Legion?type=design&node-id=10988-84243&mode=design",
    },
    codeUrl: {
      website:
        "https://gitlab.playcourt.id/telkomdev/legion-phoenix/-/tree/master/packages/core/src/components/snackbar",
      android: "#",
      ios: "https://gitlab.playcourt.id/mobileteam/legion-ios/-/tree/main/Sources/LegionUI/LGNComponent/LGNCustom/LGNSnackbar",
    },
    checklists: {
      accessibilityStandard: [
        {
          checked: true,
          text: "We don't use color as the only visual tool to convey information.",
        },
      ],
      structureProperties: [
        {
          checked: true,
          text: "The component’s structure and properties include relevant options such as variant, style, size, orientation, optional iconography, decorations, selection, error state, etc.",
        },
        {
          checked: true,
          text: "The title is the component name that uses the frame base component template.",
        },
        {
          checked: true,
          text: 'The base component name contains: .Base & "Component Name" if there is more than one.',
        },
        {
          checked: true,
          text: "All component properties use the Legion foundation.",
        },
      ],
      designImplementationTest: [
        {
          checked: true,
          text: "We can change all the parts that are connected to the component base.",
        },
        {
          checked: true,
          text: "The inside of the base component remains connected to the master component.",
        },
        {
          checked: true,
          text: "All variant options are not damaged when we change from one to another.",
        },
        {
          checked: true,
          text: "Overriding changes to components will not reset other variants.",
        },
      ],
      guidelineComponent: [
        {
          checked: true,
          text: "Component's already has component specs documentation.",
        },
      ],
    },
  },
  componentSpinner: {
    figmaUrl: {
      website:
        "https://www.figma.com/file/IAq40ai0JgOro9NSNrN95p/Website---UI-Component---Legion?type=design&node-id=11387-65574&mode=design",
      android: "#",
      ios: "#",
    },
    codeUrl: {
      website:
        "https://gitlab.playcourt.id/telkomdev/legion-phoenix/-/tree/master/packages/core/src/components/spinner",
      android: "#",
      ios: "#",
    },
    checklists: {
      accessibilityStandard: [
        {
          checked: true,
          text: "We don't use color as the only visual tool to convey information.",
        },
      ],
      structureProperties: [
        {
          checked: true,
          text: "The component’s structure and properties include relevant options such as variant, style, size, orientation, optional iconography, decorations, selection, error state, etc.",
        },
        {
          checked: true,
          text: "The title is the component name that uses the frame base component template.",
        },
        {
          checked: true,
          text: 'The base component name contains: .Base & "Component Name" if there is more than one.',
        },
        {
          checked: true,
          text: "All component properties use the Legion foundation.",
        },
      ],
      designImplementationTest: [
        {
          checked: true,
          text: "We can change all the parts that are connected to the component base.",
        },
        {
          checked: true,
          text: "The inside of the base component remains connected to the master component.",
        },
        {
          checked: true,
          text: "All variant options are not damaged when we change from one to another.",
        },
        {
          checked: true,
          text: "Overriding changes to components will not reset other variants.",
        },
      ],
      guidelineComponent: [
        {
          checked: true,
          text: "Component's already has component specs documentation.",
        },
      ],
    },
  },
  componentStepper: {
    figmaUrl: {
      website:
        "https://www.figma.com/file/IAq40ai0JgOro9NSNrN95p/Website---UI-Component---Legion?type=design&node-id=12459-100041&mode=design",
      android: "#",
      ios: "#",
    },
    codeUrl: {
      website:
        "https://gitlab.playcourt.id/telkomdev/legion-phoenix/-/tree/master/packages/core/src/components/stepper",
      android: "#",
      ios: "#",
    },
    checklists: {
      accessibilityStandard: [
        {
          checked: true,
          text: "We don't use color as the only visual tool to convey information.",
        },
      ],
      structureProperties: [
        {
          checked: true,
          text: "The component’s structure and properties include relevant options such as variant, style, size, orientation, optional iconography, decorations, selection, error state, etc.",
        },
        {
          checked: true,
          text: "The title is the component name that uses the frame base component template.",
        },
        {
          checked: true,
          text: 'The base component name contains: .Base & "Component Name" if there is more than one.',
        },
        {
          checked: true,
          text: "All component properties use the Legion foundation.",
        },
      ],
      designImplementationTest: [
        {
          checked: true,
          text: "We can change all the parts that are connected to the component base.",
        },
        {
          checked: true,
          text: "The inside of the base component remains connected to the master component.",
        },
        {
          checked: true,
          text: "All variant options are not damaged when we change from one to another.",
        },
        {
          checked: true,
          text: "Overriding changes to components will not reset other variants.",
        },
      ],
      guidelineComponent: [
        {
          checked: true,
          text: "Component's already has component specs documentation.",
        },
      ],
    },
  },
  componentSwitch: {
    figmaUrl: {
      website:
        "https://www.figma.com/file/IAq40ai0JgOro9NSNrN95p/Website---UI-Component---Legion?type=design&node-id=11024-49946&mode=design",
      android:
        "https://www.figma.com/file/BLAvGtpuuckn60NVPCktPD/Android---UI-Component---Legion?type=design&node-id=8486-79724&mode=design",
      ios: "https://www.figma.com/file/E55RZgxAnQaW51BDEWCaVS/Mobile---UI-Component---Legion?type=design&node-id=8486-79724&mode=design",
    },
    codeUrl: {
      website:
        "https://gitlab.playcourt.id/telkomdev/legion-phoenix/-/tree/master/packages/core/src/components/switch",
      android: "#",
      ios: "https://gitlab.playcourt.id/mobileteam/legion-ios/-/tree/main/Sources/LegionUI/LGNComponent/LGNCustom/LGNSwitch",
    },
    checklists: {
      accessibilityStandard: [
        {
          checked: true,
          text: "We don't use color as the only visual tool to convey information.",
        },
      ],
      structureProperties: [
        {
          checked: true,
          text: "The component’s structure and properties include relevant options such as variant, style, size, orientation, optional iconography, decorations, selection, error state, etc.",
        },
        {
          checked: true,
          text: "The title is the component name that uses the frame base component template.",
        },
        {
          checked: true,
          text: 'The base component name contains: .Base & "Component Name" if there is more than one.',
        },
        {
          checked: true,
          text: "All component properties use the Legion foundation.",
        },
      ],
      designImplementationTest: [
        {
          checked: true,
          text: "We can change all the parts that are connected to the component base.",
        },
        {
          checked: true,
          text: "The inside of the base component remains connected to the master component.",
        },
        {
          checked: true,
          text: "All variant options are not damaged when we change from one to another.",
        },
        {
          checked: true,
          text: "Overriding changes to components will not reset other variants.",
        },
      ],
      guidelineComponent: [
        {
          checked: true,
          text: "Component's already has component specs documentation.",
        },
      ],
    },
  },
  componentTabBar: {
    figmaUrl: {
      website:
        "https://www.figma.com/file/IAq40ai0JgOro9NSNrN95p/Website---UI-Component---Legion?type=design&node-id=11024-59794&mode=design",
      android: "#",
      ios: "https://www.figma.com/file/E55RZgxAnQaW51BDEWCaVS/Mobile---UI-Component---Legion?type=design&node-id=10988-77970&mode=design",
    },
    codeUrl: {
      website:
        "https://gitlab.playcourt.id/telkomdev/legion-phoenix/-/tree/master/packages/core/src/components/tabs",
      android: "#",
      ios: "#",
    },
    checklists: {
      accessibilityStandard: [
        {
          checked: true,
          text: "We don't use color as the only visual tool to convey information.",
        },
      ],
      structureProperties: [
        {
          checked: true,
          text: "The component’s structure and properties include relevant options such as variant, style, size, orientation, optional iconography, decorations, selection, error state, etc.",
        },
        {
          checked: true,
          text: "The title is the component name that uses the frame base component template.",
        },
        {
          checked: true,
          text: 'The base component name contains: .Base & "Component Name" if there is more than one.',
        },
        {
          checked: true,
          text: "All component properties use the Legion foundation.",
        },
      ],
      designImplementationTest: [
        {
          checked: true,
          text: "We can change all the parts that are connected to the component base.",
        },
        {
          checked: true,
          text: "The inside of the base component remains connected to the master component.",
        },
        {
          checked: true,
          text: "All variant options are not damaged when we change from one to another.",
        },
        {
          checked: true,
          text: "Overriding changes to components will not reset other variants.",
        },
      ],
      guidelineComponent: [
        {
          checked: true,
          text: "Component's already has component specs documentation.",
        },
      ],
    },
  },
  componentTable: {
    figmaUrl: {
      website:
        "https://www.figma.com/file/IAq40ai0JgOro9NSNrN95p/Website---UI-Component---Legion?type=design&node-id=11387-66018&mode=design",
      android: "#",
      ios: "#",
    },
    codeUrl: {
      website:
        "https://gitlab.playcourt.id/telkomdev/legion-phoenix/-/tree/master/packages/core/src/components/table",
      android: "#",
      ios: "#",
    },
    checklists: {
      accessibilityStandard: [
        {
          checked: true,
          text: "We don't use color as the only visual tool to convey information.",
        },
      ],
      structureProperties: [
        {
          checked: true,
          text: "The component’s structure and properties include relevant options such as variant, style, size, orientation, optional iconography, decorations, selection, error state, etc.",
        },
        {
          checked: true,
          text: "The title is the component name that uses the frame base component template.",
        },
        {
          checked: true,
          text: 'The base component name contains: .Base & "Component Name" if there is more than one.',
        },
        {
          checked: true,
          text: "All component properties use the Legion foundation.",
        },
      ],
      designImplementationTest: [
        {
          checked: true,
          text: "We can change all the parts that are connected to the component base.",
        },
        {
          checked: true,
          text: "The inside of the base component remains connected to the master component.",
        },
        {
          checked: true,
          text: "All variant options are not damaged when we change from one to another.",
        },
        {
          checked: true,
          text: "Overriding changes to components will not reset other variants.",
        },
      ],
      guidelineComponent: [
        {
          checked: true,
          text: "Component's already has component specs documentation.",
        },
      ],
    },
  },
  componentTextArea: {
    figmaUrl: {
      website:
        "https://www.figma.com/file/IAq40ai0JgOro9NSNrN95p/Website---UI-Component---Legion?type=design&node-id=11024-59122&mode=design",
      android:
        "https://www.figma.com/file/BLAvGtpuuckn60NVPCktPD/Android---UI-Component---Legion?type=design&node-id=14755-2744&mode=design",
      ios: "https://www.figma.com/file/E55RZgxAnQaW51BDEWCaVS/Mobile---UI-Component---Legion?type=design&node-id=10988-84746&mode=design",
    },
    codeUrl: {
      website:
        "https://gitlab.playcourt.id/telkomdev/legion-phoenix/-/tree/master/packages/core/src/components/textarea",
      android: "#",
      ios: "https://gitlab.playcourt.id/mobileteam/legion-ios/-/tree/main/Sources/LegionUI/LGNComponent/LGNCustom/LGNTxtView",
    },
    checklists: {
      accessibilityStandard: [
        {
          checked: true,
          text: "We don't use color as the only visual tool to convey information.",
        },
      ],
      structureProperties: [
        {
          checked: true,
          text: "The component’s structure and properties include relevant options such as variant, style, size, orientation, optional iconography, decorations, selection, error state, etc.",
        },
        {
          checked: true,
          text: "The title is the component name that uses the frame base component template.",
        },
        {
          checked: true,
          text: 'The base component name contains: .Base & "Component Name" if there is more than one.',
        },
        {
          checked: true,
          text: "All component properties use the Legion foundation.",
        },
      ],
      designImplementationTest: [
        {
          checked: true,
          text: "We can change all the parts that are connected to the component base.",
        },
        {
          checked: true,
          text: "The inside of the base component remains connected to the master component.",
        },
        {
          checked: true,
          text: "All variant options are not damaged when we change from one to another.",
        },
        {
          checked: true,
          text: "Overriding changes to components will not reset other variants.",
        },
      ],
      guidelineComponent: [
        {
          checked: true,
          text: "Component's already has component specs documentation.",
        },
      ],
    },
  },
  componentTextField: {
    figmaUrl: {
      website:
        "https://www.figma.com/file/IAq40ai0JgOro9NSNrN95p/Website---UI-Component---Legion?type=design&node-id=6655-218380&mode=design",
      android:
        "https://www.figma.com/file/BLAvGtpuuckn60NVPCktPD/Android---UI-Component---Legion?type=design&node-id=6655-218380&mode=design",
      ios: "https://www.figma.com/file/E55RZgxAnQaW51BDEWCaVS/Mobile---UI-Component---Legion?type=design&node-id=6655-218380&mode=design",
    },
    codeUrl: {
      website:
        "https://gitlab.playcourt.id/telkomdev/legion-phoenix/-/tree/master/packages/core/src/components/textfield",
      android: "#",
      ios: "https://gitlab.playcourt.id/mobileteam/legion-ios/-/tree/main/Sources/LegionUI/LGNComponent/LGNCustom/LGNTxtField",
    },
    checklists: {
      accessibilityStandard: [
        {
          checked: true,
          text: "We don't use color as the only visual tool to convey information.",
        },
      ],
      structureProperties: [
        {
          checked: true,
          text: "The component’s structure and properties include relevant options such as variant, style, size, orientation, optional iconography, decorations, selection, error state, etc.",
        },
        {
          checked: true,
          text: "The title is the component name that uses the frame base component template.",
        },
        {
          checked: true,
          text: 'The base component name contains: .Base & "Component Name" if there is more than one.',
        },
        {
          checked: true,
          text: "All component properties use the Legion foundation.",
        },
      ],
      designImplementationTest: [
        {
          checked: true,
          text: "We can change all the parts that are connected to the component base.",
        },
        {
          checked: true,
          text: "The inside of the base component remains connected to the master component.",
        },
        {
          checked: true,
          text: "All variant options are not damaged when we change from one to another.",
        },
        {
          checked: true,
          text: "Overriding changes to components will not reset other variants.",
        },
      ],
      guidelineComponent: [
        {
          checked: true,
          text: "Component's already has component specs documentation.",
        },
      ],
    },
  },
  componentTooltip: {
    figmaUrl: {
      website:
        "https://www.figma.com/file/IAq40ai0JgOro9NSNrN95p/Website---UI-Component---Legion?type=design&node-id=10673-70589&mode=design",
      android: "#",
      ios: "https://www.figma.com/file/E55RZgxAnQaW51BDEWCaVS/Mobile---UI-Component---Legion?type=design&node-id=10065-207059&mode=design",
    },
    codeUrl: {
      website:
        "https://gitlab.playcourt.id/telkomdev/legion-phoenix/-/tree/master/packages/core/src/components/tooltip",
      android: "#",
      ios: "https://gitlab.playcourt.id/mobileteam/legion-ios/-/tree/main/Sources/LegionUI/LGNComponent/LGNCustom/LGNTooltip",
    },
    checklists: {
      accessibilityStandard: [
        {
          checked: true,
          text: "We don't use color as the only visual tool to convey information.",
        },
      ],
      structureProperties: [
        {
          checked: true,
          text: "The component’s structure and properties include relevant options such as variant, style, size, orientation, optional iconography, decorations, selection, error state, etc.",
        },
        {
          checked: true,
          text: "The title is the component name that uses the frame base component template.",
        },
        {
          checked: true,
          text: 'The base component name contains: .Base & "Component Name" if there is more than one.',
        },
        {
          checked: true,
          text: "All component properties use the Legion foundation.",
        },
      ],
      designImplementationTest: [
        {
          checked: true,
          text: "We can change all the parts that are connected to the component base.",
        },
        {
          checked: true,
          text: "The inside of the base component remains connected to the master component.",
        },
        {
          checked: true,
          text: "All variant options are not damaged when we change from one to another.",
        },
        {
          checked: true,
          text: "Overriding changes to components will not reset other variants.",
        },
      ],
      guidelineComponent: [
        {
          checked: true,
          text: "Component's already has component specs documentation.",
        },
      ],
    },
  },
  componentViewState: {
    figmaUrl: {
      website: "#",
      android: "#",
      ios: "#",
    },
    codeUrl: { website: "#", android: "#", ios: "#" },
    checklists: {
      accessibilityStandard: [
        {
          checked: true,
          text: "We don't use color as the only visual tool to convey information.",
        },
      ],
      structureProperties: [
        {
          checked: true,
          text: "The component’s structure and properties include relevant options such as variant, style, size, orientation, optional iconography, decorations, selection, error state, etc.",
        },
        {
          checked: true,
          text: "The title is the component name that uses the frame base component template.",
        },
        {
          checked: true,
          text: 'The base component name contains: .Base & "Component Name" if there is more than one.',
        },
        {
          checked: true,
          text: "All component properties use the Legion foundation.",
        },
      ],
      designImplementationTest: [
        {
          checked: true,
          text: "We can change all the parts that are connected to the component base.",
        },
        {
          checked: true,
          text: "The inside of the base component remains connected to the master component.",
        },
        {
          checked: true,
          text: "All variant options are not damaged when we change from one to another.",
        },
        {
          checked: true,
          text: "Overriding changes to components will not reset other variants.",
        },
      ],
      guidelineComponent: [
        {
          checked: true,
          text: "Component's already has component specs documentation.",
        },
      ],
    },
  },
  componentBreadcrumb: {
    figmaUrl: {
      website: "#",
      android: "#",
      ios: "#",
    },
    codeUrl: { website: "#", android: "#", ios: "#" },
    checklists: {
      accessibilityStandard: [
        {
          checked: true,
          text: "We don't use color as the only visual tool to convey information.",
        },
      ],
      structureProperties: [
        {
          checked: true,
          text: "The component’s structure and properties include relevant options such as variant, style, size, orientation, optional iconography, decorations, selection, error state, etc.",
        },
        {
          checked: true,
          text: "The title is the component name that uses the frame base component template.",
        },
        {
          checked: true,
          text: 'The base component name contains: .Base & "Component Name" if there is more than one.',
        },
        {
          checked: true,
          text: "All component properties use the Legion foundation.",
        },
      ],
      designImplementationTest: [
        {
          checked: true,
          text: "We can change all the parts that are connected to the component base.",
        },
        {
          checked: true,
          text: "The inside of the base component remains connected to the master component.",
        },
        {
          checked: true,
          text: "All variant options are not damaged when we change from one to another.",
        },
        {
          checked: true,
          text: "Overriding changes to components will not reset other variants.",
        },
      ],
      guidelineComponent: [
        {
          checked: true,
          text: "Component's already has component specs documentation.",
        },
      ],
    },
  },
  componentContainer: {
    figmaUrl: {
      website: "#",
      android: "#",
      ios: "#",
    },
    codeUrl: { website: "#", android: "#", ios: "#" },
    checklists: {
      accessibilityStandard: [
        {
          checked: true,
          text: "We don't use color as the only visual tool to convey information.",
        },
      ],
      structureProperties: [
        {
          checked: true,
          text: "The component’s structure and properties include relevant options such as variant, style, size, orientation, optional iconography, decorations, selection, error state, etc.",
        },
        {
          checked: true,
          text: "The title is the component name that uses the frame base component template.",
        },
        {
          checked: true,
          text: 'The base component name contains: .Base & "Component Name" if there is more than one.',
        },
        {
          checked: true,
          text: "All component properties use the Legion foundation.",
        },
      ],
      designImplementationTest: [
        {
          checked: true,
          text: "We can change all the parts that are connected to the component base.",
        },
        {
          checked: true,
          text: "The inside of the base component remains connected to the master component.",
        },
        {
          checked: true,
          text: "All variant options are not damaged when we change from one to another.",
        },
        {
          checked: true,
          text: "Overriding changes to components will not reset other variants.",
        },
      ],
      guidelineComponent: [
        {
          checked: true,
          text: "Component's already has component specs documentation.",
        },
      ],
    },
  },
  componentFileUpload: {
    figmaUrl: {
      website: "#",
      android: "#",
      ios: "#",
    },
    codeUrl: { website: "#", android: "#", ios: "#" },
    checklists: {
      accessibilityStandard: [
        {
          checked: true,
          text: "We don't use color as the only visual tool to convey information.",
        },
      ],
      structureProperties: [
        {
          checked: true,
          text: "The component’s structure and properties include relevant options such as variant, style, size, orientation, optional iconography, decorations, selection, error state, etc.",
        },
        {
          checked: true,
          text: "The title is the component name that uses the frame base component template.",
        },
        {
          checked: true,
          text: 'The base component name contains: .Base & "Component Name" if there is more than one.',
        },
        {
          checked: true,
          text: "All component properties use the Legion foundation.",
        },
      ],
      designImplementationTest: [
        {
          checked: true,
          text: "We can change all the parts that are connected to the component base.",
        },
        {
          checked: true,
          text: "The inside of the base component remains connected to the master component.",
        },
        {
          checked: true,
          text: "All variant options are not damaged when we change from one to another.",
        },
        {
          checked: true,
          text: "Overriding changes to components will not reset other variants.",
        },
      ],
      guidelineComponent: [
        {
          checked: true,
          text: "Component's already has component specs documentation.",
        },
      ],
    },
  },
  componentList: {
    figmaUrl: {
      website: "#",
      android: "#",
      ios: "#",
    },
    codeUrl: { website: "#", android: "#", ios: "#" },
    checklists: {
      accessibilityStandard: [
        {
          checked: true,
          text: "We don't use color as the only visual tool to convey information.",
        },
      ],
      structureProperties: [
        {
          checked: true,
          text: "The component’s structure and properties include relevant options such as variant, style, size, orientation, optional iconography, decorations, selection, error state, etc.",
        },
        {
          checked: true,
          text: "The title is the component name that uses the frame base component template.",
        },
        {
          checked: true,
          text: 'The base component name contains: .Base & "Component Name" if there is more than one.',
        },
        {
          checked: true,
          text: "All component properties use the Legion foundation.",
        },
      ],
      designImplementationTest: [
        {
          checked: true,
          text: "We can change all the parts that are connected to the component base.",
        },
        {
          checked: true,
          text: "The inside of the base component remains connected to the master component.",
        },
        {
          checked: true,
          text: "All variant options are not damaged when we change from one to another.",
        },
        {
          checked: true,
          text: "Overriding changes to components will not reset other variants.",
        },
      ],
      guidelineComponent: [
        {
          checked: true,
          text: "Component's already has component specs documentation.",
        },
      ],
    },
  },
  componentPagination: {
    figmaUrl: {
      website: "#",
      android: "#",
      ios: "#",
    },
    codeUrl: { website: "#", android: "#", ios: "#" },
    checklists: {
      accessibilityStandard: [
        {
          checked: true,
          text: "We don't use color as the only visual tool to convey information.",
        },
      ],
      structureProperties: [
        {
          checked: true,
          text: "The component’s structure and properties include relevant options such as variant, style, size, orientation, optional iconography, decorations, selection, error state, etc.",
        },
        {
          checked: true,
          text: "The title is the component name that uses the frame base component template.",
        },
        {
          checked: true,
          text: 'The base component name contains: .Base & "Component Name" if there is more than one.',
        },
        {
          checked: true,
          text: "All component properties use the Legion foundation.",
        },
      ],
      designImplementationTest: [
        {
          checked: true,
          text: "We can change all the parts that are connected to the component base.",
        },
        {
          checked: true,
          text: "The inside of the base component remains connected to the master component.",
        },
        {
          checked: true,
          text: "All variant options are not damaged when we change from one to another.",
        },
        {
          checked: true,
          text: "Overriding changes to components will not reset other variants.",
        },
      ],
      guidelineComponent: [
        {
          checked: true,
          text: "Component's already has component specs documentation.",
        },
      ],
    },
  },
  componentProgressBar: {
    figmaUrl: {
      website: "#",
      android: "#",
      ios: "#",
    },
    codeUrl: { website: "#", android: "#", ios: "#" },
    checklists: {
      accessibilityStandard: [
        {
          checked: true,
          text: "We don't use color as the only visual tool to convey information.",
        },
      ],
      structureProperties: [
        {
          checked: true,
          text: "The component’s structure and properties include relevant options such as variant, style, size, orientation, optional iconography, decorations, selection, error state, etc.",
        },
        {
          checked: true,
          text: "The title is the component name that uses the frame base component template.",
        },
        {
          checked: true,
          text: 'The base component name contains: .Base & "Component Name" if there is more than one.',
        },
        {
          checked: true,
          text: "All component properties use the Legion foundation.",
        },
      ],
      designImplementationTest: [
        {
          checked: true,
          text: "We can change all the parts that are connected to the component base.",
        },
        {
          checked: true,
          text: "The inside of the base component remains connected to the master component.",
        },
        {
          checked: true,
          text: "All variant options are not damaged when we change from one to another.",
        },
        {
          checked: true,
          text: "Overriding changes to components will not reset other variants.",
        },
      ],
      guidelineComponent: [
        {
          checked: true,
          text: "Component's already has component specs documentation.",
        },
      ],
    },
  },
  componentProgressCircle: {
    figmaUrl: {
      website: "#",
      android: "#",
      ios: "#",
    },
    codeUrl: { website: "#", android: "#", ios: "#" },
    checklists: {
      accessibilityStandard: [
        {
          checked: true,
          text: "We don't use color as the only visual tool to convey information.",
        },
      ],
      structureProperties: [
        {
          checked: true,
          text: "The component’s structure and properties include relevant options such as variant, style, size, orientation, optional iconography, decorations, selection, error state, etc.",
        },
        {
          checked: true,
          text: "The title is the component name that uses the frame base component template.",
        },
        {
          checked: true,
          text: 'The base component name contains: .Base & "Component Name" if there is more than one.',
        },
        {
          checked: true,
          text: "All component properties use the Legion foundation.",
        },
      ],
      designImplementationTest: [
        {
          checked: true,
          text: "We can change all the parts that are connected to the component base.",
        },
        {
          checked: true,
          text: "The inside of the base component remains connected to the master component.",
        },
        {
          checked: true,
          text: "All variant options are not damaged when we change from one to another.",
        },
        {
          checked: true,
          text: "Overriding changes to components will not reset other variants.",
        },
      ],
      guidelineComponent: [
        {
          checked: true,
          text: "Component's already has component specs documentation.",
        },
      ],
    },
  },
  componentRating: {
    figmaUrl: {
      website: "#",
      android: "#",
      ios: "#",
    },
    codeUrl: { website: "#", android: "#", ios: "#" },
    checklists: {
      accessibilityStandard: [
        {
          checked: true,
          text: "We don't use color as the only visual tool to convey information.",
        },
      ],
      structureProperties: [
        {
          checked: true,
          text: "The component’s structure and properties include relevant options such as variant, style, size, orientation, optional iconography, decorations, selection, error state, etc.",
        },
        {
          checked: true,
          text: "The title is the component name that uses the frame base component template.",
        },
        {
          checked: true,
          text: 'The base component name contains: .Base & "Component Name" if there is more than one.',
        },
        {
          checked: true,
          text: "All component properties use the Legion foundation.",
        },
      ],
      designImplementationTest: [
        {
          checked: true,
          text: "We can change all the parts that are connected to the component base.",
        },
        {
          checked: true,
          text: "The inside of the base component remains connected to the master component.",
        },
        {
          checked: true,
          text: "All variant options are not damaged when we change from one to another.",
        },
        {
          checked: true,
          text: "Overriding changes to components will not reset other variants.",
        },
      ],
      guidelineComponent: [
        {
          checked: true,
          text: "Component's already has component specs documentation.",
        },
      ],
    },
  },
  componentSlider: {
    figmaUrl: {
      website: "#",
      android: "#",
      ios: "#",
    },
    codeUrl: { website: "#", android: "#", ios: "#" },
    checklists: {
      accessibilityStandard: [
        {
          checked: true,
          text: "We don't use color as the only visual tool to convey information.",
        },
      ],
      structureProperties: [
        {
          checked: true,
          text: "The component’s structure and properties include relevant options such as variant, style, size, orientation, optional iconography, decorations, selection, error state, etc.",
        },
        {
          checked: true,
          text: "The title is the component name that uses the frame base component template.",
        },
        {
          checked: true,
          text: 'The base component name contains: .Base & "Component Name" if there is more than one.',
        },
        {
          checked: true,
          text: "All component properties use the Legion foundation.",
        },
      ],
      designImplementationTest: [
        {
          checked: true,
          text: "We can change all the parts that are connected to the component base.",
        },
        {
          checked: true,
          text: "The inside of the base component remains connected to the master component.",
        },
        {
          checked: true,
          text: "All variant options are not damaged when we change from one to another.",
        },
        {
          checked: true,
          text: "Overriding changes to components will not reset other variants.",
        },
      ],
      guidelineComponent: [
        {
          checked: true,
          text: "Component's already has component specs documentation.",
        },
      ],
    },
  },
  componentTimePicker: {
    figmaUrl: {
      website: "#",
      android: "#",
      ios: "#",
    },
    codeUrl: { website: "#", android: "#", ios: "#" },
    checklists: {
      accessibilityStandard: [
        {
          checked: true,
          text: "We don't use color as the only visual tool to convey information.",
        },
      ],
      structureProperties: [
        {
          checked: true,
          text: "The component’s structure and properties include relevant options such as variant, style, size, orientation, optional iconography, decorations, selection, error state, etc.",
        },
        {
          checked: true,
          text: "The title is the component name that uses the frame base component template.",
        },
        {
          checked: true,
          text: 'The base component name contains: .Base & "Component Name" if there is more than one.',
        },
        {
          checked: true,
          text: "All component properties use the Legion foundation.",
        },
      ],
      designImplementationTest: [
        {
          checked: true,
          text: "We can change all the parts that are connected to the component base.",
        },
        {
          checked: true,
          text: "The inside of the base component remains connected to the master component.",
        },
        {
          checked: true,
          text: "All variant options are not damaged when we change from one to another.",
        },
        {
          checked: true,
          text: "Overriding changes to components will not reset other variants.",
        },
      ],
      guidelineComponent: [
        {
          checked: true,
          text: "Component's already has component specs documentation.",
        },
      ],
    },
  },
  componentTree: {
    figmaUrl: {
      website: "#",
      android: "#",
      ios: "#",
    },
    codeUrl: { website: "#", android: "#", ios: "#" },
    checklists: {
      accessibilityStandard: [
        {
          checked: true,
          text: "We don't use color as the only visual tool to convey information.",
        },
      ],
      structureProperties: [
        {
          checked: true,
          text: "The component’s structure and properties include relevant options such as variant, style, size, orientation, optional iconography, decorations, selection, error state, etc.",
        },
        {
          checked: true,
          text: "The title is the component name that uses the frame base component template.",
        },
        {
          checked: true,
          text: 'The base component name contains: .Base & "Component Name" if there is more than one.',
        },
        {
          checked: true,
          text: "All component properties use the Legion foundation.",
        },
      ],
      designImplementationTest: [
        {
          checked: true,
          text: "We can change all the parts that are connected to the component base.",
        },
        {
          checked: true,
          text: "The inside of the base component remains connected to the master component.",
        },
        {
          checked: true,
          text: "All variant options are not damaged when we change from one to another.",
        },
        {
          checked: true,
          text: "Overriding changes to components will not reset other variants.",
        },
      ],
      guidelineComponent: [
        {
          checked: true,
          text: "Component's already has component specs documentation.",
        },
      ],
    },
  },
};

const release = {
  keyNewRelease: "2.76.0",
  date: "27 March 2025",
  notes: "Work Smarter with our Figma to Code!"
};

export { checklists, release, themes, themesList };
